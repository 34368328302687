import { Box, Button, Container, Grid } from '@mui/material'
import React, {useState, useEffect} from 'react'
import './LandHeader.css'
import Logo from '../../assets/images/logo.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const LandHeader = () => {

  const [burger, setBurger] = useState(false);

  const [headpos, setHeadpos] = useState(true);

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    if(scrollPosition > 600){
      setHeadpos(false);
    }
    else{
      setHeadpos(true);
    }
};
useEffect(() => {
  handleScroll();
  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
  }, []);

  return (
    <div className='landHeader' style={{position: headpos ?'absolute':'fixed'}}>
    <Container maxWidth="xl">
            <Box sx={{ flexGrow: 1, py: 2.5 }}>
      <Grid container spacing={2}>
        <Grid item md={2} xs={4}>
            <img src={Logo} alt='logo' width={50}></img>
        </Grid>
        <Grid item xs={7}  sx={{display:{xs:'none', md:'flex'}, alignItems:'center', justifyContent:'center'}}>
        <Link to='/'>
        <Button variant='text' sx={{px: 4}}>Products</Button>
        </Link>
        <Link to='/integration'>
        <Button variant='text' sx={{px: 4}}>Integration</Button>
        </Link>
        <Link to='/about-us'>
        <Button variant='text' sx={{px: 4}}>About Us</Button>
        </Link>
        </Grid>
        <Grid item  md={3} xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'end'}}>
        <Link to='/contact'>
        <Button variant='outlined' size='large' sx={{ borderRadius: 8,}}>Contact</Button>
        </Link>
        </Grid>
        <Grid item  md={2} xs={4} sx={{display:{xs:'flex', md:'none'}, alignItems:'center', justifyContent:'end'}}>
        <MenuIcon onClick={()=> setBurger(!burger)} sx={{fontSize: 35}}/>
        </Grid>
      </Grid>
      {burger &&
      <Box  sx={{display:{xs:'flex', md:'none'}, alignItems:'center', justifyContent:'center', flexDirection:'column', mt: 2, gap: 2}}>
        <Link to='/'>
        <Button variant='text' sx={{px: 4}}>Products</Button>
        </Link>
        <Link to='/integration'>
        <Button variant='text' sx={{px: 4}}>Integration</Button>
        </Link>
        <Link to='/about-us'>
        <Button variant='text' sx={{px: 4}}>About Us</Button>
        </Link>
        </Box>
      }
    </Box>
    </Container>
    </div>
  )
}

export default LandHeader