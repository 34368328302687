import React, {useState} from 'react';
import Dashboard from './pages/dashboard/Dashboard.js';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Login from './pages/login/Login.js';
import Dealer from './pages/dealer/Dealer.js';
import Orders from './pages/orders/Orders.js';
import Products from './pages/products/Products.js';
import Inventory from './pages/inventory/Inventory.js';
import Warranty from './pages/warranty/Warranty.js';
import Challan from './pages/deliverychallan/Challan.js';
import Report from './pages/report/Report.js';
import Setting from './pages/settings/Setting.js';
import Layout2 from './Layout2.js';
import Home from './pages/home/Home.js';
import Integration from './pages/integration/Integration.js';
import Aboutus from './pages/aboutus/Aboutus.js';
import Contact from './pages/Contact/Contact.js';
import ProtectedRoute from './utils/ProtectedRoute.js';
import AddDealer from './pages/dealer/Adddealer.js';
import Createregion from './pages/settings/Createregion.js';
import Createuser from './pages/settings/Createuser.js';
import AddProduct from './pages/products/Addproduct.js';
import AddSpare from './pages/products/Addspare.js';
import AddAccessory from './pages/products/Addaccessory.js';
import AddProductStock from './pages/inventory/Addproductstock.js';
import AddSpareStock from './pages/inventory/Addsparestock.js';
import AddAccessoryStock from './pages/inventory/Addaccessorystock.js';
import AddChallan from './pages/deliverychallan/Addchallan.js';
import UpdateDealer from './pages/dealer/Updatedealer.js';
import Purchasedetail from './pages/orders/Purchasesdetail.js';
import EditSpare from './pages/products/Editspare.js';
import EditAccessory from './pages/products/Editaccessory.js';
import Warrantydetail from './pages/warranty/Warrantydetail.js';
import Viewdealer from './pages/dealer/Viewdealer.js';
import Support from './pages/support/Support.js';


const RouteRoute = ({setLoader}) => {

  const [show, setShow] = useState(true);

  return (
    <>
    <Routes>
    <Route path='/' element={<Layout2/>}>
        <Route path='/' exact element={<Home setLoader={setLoader}/>}></Route> 
        <Route path='/integration' exact element={<Integration setLoader={setLoader}/>}></Route> 
        <Route path='/about-us' exact element={<Aboutus setLoader={setLoader}/>}></Route> 
        <Route path='/contact' exact element={<Contact setLoader={setLoader}/>}></Route> 
        </Route>
        {/* <Route path='/login' element={<Login setLoader={setLoader}/>}></Route> 
      <Route path='/' element={<Layout show={show} setShow={setShow}/>}>
        <Route path='/dashboard' element={<ProtectedRoute><Dashboard show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/dealer'>
              <Route index={true} element={<ProtectedRoute><Dealer show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              <Route path='new' element={<ProtectedRoute><AddDealer show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='update/:dealerId' element={<ProtectedRoute><UpdateDealer show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='detail/:dealerId' element={<ProtectedRoute><Viewdealer show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/order'>
              <Route index={true} element={<ProtectedRoute><Orders show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='detail/:purchaseID' element={<ProtectedRoute><Purchasedetail show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/product'>
              <Route index={true} element={<ProtectedRoute><Products show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addproduct' element={<ProtectedRoute><AddProduct show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addspare' element={<ProtectedRoute><AddSpare show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addaccessory' element={<ProtectedRoute><AddAccessory show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='updatespare/:spareId' element={<ProtectedRoute><EditSpare show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='updateaccess/:accessId' element={<ProtectedRoute><EditAccessory show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/inventory'>
              <Route index={true} element={<ProtectedRoute><Inventory show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addproductstock' element={<ProtectedRoute><AddProductStock show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addsparestock' element={<ProtectedRoute><AddSpareStock show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='addaccessorystock' element={<ProtectedRoute><AddAccessoryStock show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/warranty'>
            <Route index={true} element={<ProtectedRoute><Warranty show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
            <Route path='warrantydetail/:warrantyId' element={<ProtectedRoute><Warrantydetail show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/deliverychallan'>
              <Route index={true} element={<ProtectedRoute><Challan show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              <Route path='addchallan' element={<ProtectedRoute><AddChallan show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/report' element={<ProtectedRoute><Report show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/setting' >
            <Route index={true} element={<ProtectedRoute><Setting show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
            <Route path='createregion' element={<ProtectedRoute><Createregion show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
            <Route path='createuser' element={<ProtectedRoute><Createuser show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/support' element={<ProtectedRoute><Support show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
      </Route> */}
    </Routes>
    </>
  )
}

export default RouteRoute;