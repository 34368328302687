import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import takepic from "../../assets/images/takeapick.png";
import temp from "../../assets/images/choosetemp.png";
import studio from "../../assets/images/studio.png";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import { LocationCityOutlined } from "@mui/icons-material";

const Contact = () => {
  return (
    <div>
      <div className="firstSection">
        <Container maxWidth="xl">
          <Box
            sx={{
              marginTop:{ xs:'130px', md:'200px'},
              marginBottom: '50px'
            }}
          >
          <Grid container spacing={3} sx={{alignItems:'center'}}>
              <Grid item md={6} xs={12}>
              <Typography
              variant="h4"
              sx={{
                fontFamily: "'Sora', sans-serif",
                fontWeight: "bold",
                marginTop: 2,
              }}
            >
             Contact Us
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "32px",
                mt: 2
              }}
            >
Curious about something or want to connect? We're here, ready to engage with you.            </Typography>
<Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "32px",
              }}
            >
Seeking insight or a chance to connect? We're here, poised and enthusiastic to engage with you. </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <img src="https://www.ohoh.in/wp-content/uploads/2023/12/Service-Center-DMS-2048x1272.webp" alt="contact" width="100%"></img>
              </Grid>
          </Grid>
          </Box>
        </Container>
      </div>
      <Box className="secondSec" sx={{ position: "relative" }}>
        <Container maxWidth="xl">
          <Box sx={{pt: 3}}>
           <Grid container spacing={3}>
  <Grid item md={6} xs={12}>
                <Box>
                    <Typography sx={{mb: 1}} variant="h5">Contact Details</Typography>
                    <div style={{display:'flex', justifyContent:'start', alignItems:'center', gap: '10px', margin:'20px 0px'}}>
                    <MailOutlineIcon color="primary"/>
                    <Typography variant="p" sx={{ lineHeight: '28px', fontSize: '18px'}}>info@dealerpro.in</Typography>
                    </div>
                    <div style={{display:'flex', justifyContent:'start', alignItems:'center', gap: '10px', margin:'20px 0px'}}>
                    <CallIcon color="primary"/>
                    <Typography variant="p" sx={{ lineHeight: '28px', fontSize: '18px'}}>+91 97765 65575</Typography>
                    </div>
                </Box>
                <Box>
                    <Typography sx={{mb: 1}} variant="h5">Address</Typography>
                    <div style={{display:'flex', justifyContent:'start', alignItems:'center', gap: '10px', margin:'20px 0px'}}>
                    <LocationCityOutlined color="primary"/>
                    <Typography variant="p" sx={{ lineHeight: '28px', fontSize: '18px'}}>656/B1,B2, First floor, Kanji - Tiruvannamalai Rd, <br></br> Tiruvannamalai, Tamil Nadu 606604</Typography>
                    </div>
                </Box>
 </Grid>
  <Grid item md={6} xs={12}>
  <Card sx={{ p: 5, bgcolor:'light.light', boxShadow:'none', borderRadius: 2, border:'1px solid #b0b0b0'}}>
                <Box>
                    <Typography sx={{mb: 1}} variant="h5">Let's Connect</Typography>
                    <Typography variant="p" sx={{mt: 2, lineHeight: '28px', fontSize: '18px'}}>Show your ambition</Typography>
                    <Grid container spacing={2} sx={{mt: 0.5}}>
                      <Grid item xs={12}>
                      <Typography variant="h6">Your full name *</Typography>
                      <input type="text" style={{width:'100%', padding: '10px'}}></input>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography variant="h6">Your email ID</Typography>
                      <input type="email" style={{width:'100%', padding: '10px'}}></input>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography variant="h6">Contact number</Typography>
                      <input type="number" style={{width:'100%', padding: '10px'}}></input>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography variant="h6">Company Name *</Typography>
                      <input type="text" style={{width:'100%', padding: '10px'}}></input>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography variant="h6">Subject *</Typography>
                      <input type="text" style={{width:'100%', padding: '10px'}}></input>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography variant="h6">Your message</Typography>
                      <textarea type="text" rows={4} placeholder="We'd love to hear from you." style={{width:'100%', padding: '10px'}}></textarea>
                      </Grid>
                      <Grid item xs={12}>
                      <Button variant="contained" type="submit">Submit</Button>
                      </Grid>
                    </Grid>
                </Box>
            </Card>
  </Grid>
</Grid>
          </Box>
        </Container>
      </Box>
      <Box className="thirdSec" sx={{ position: "relative" }}>
        <Container className="whatoffer" maxWidth="xl">
          <Typography
            className="heading1"
            variant="h5"
            color="primary.main"
            sx={{ fontFamily: "'Sora', sans-serif", textAlign: "center" }}
          >
            Explore our company
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 4,
            }}
          >
            Start your trail today{" "}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "normal",
              textAlign: "center",
              my: 5,
            }}
          >
            Supercharge your Dealership with the best DMS acceleration
          </Typography>
          <button className="customizeButton">Request Demo</button>
        </Container>
      </Box>
    </div>
  );
};

export default Contact;
