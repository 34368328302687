import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import logo from '../../assets/images/logo.png';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';

const LandFooter = () => {
  return (
    <>
    <Box sx={{bgcolor:'#0C0636', marginTop: '100px', py: 5, color:'#fff'}}>
    <Container maxWidth='xl'>
        <Grid container spacing={2}>
  <Grid item xs={12} md={3}>
        <div>
        <Typography variant='h6' component='p' sx={{marginTop: 2}} >Contact</Typography>
        <Typography variant='p' component='p' sx={{marginTop: 2}} >info@ohoh.in</Typography>
        <Typography variant='p' component='p' sx={{marginTop: 2}} >sales@ohoh.in</Typography>
        <Typography variant='p' component='p' sx={{marginTop: 2}} >Tel.(+91) 93605 24391</Typography>
        </div>
          </Grid>
  <Grid item xs={12} md={3}>
  <div>
        <Typography variant='h6' component='p' sx={{marginTop: 2}} >Company</Typography>
        <Typography variant='p' component='p' sx={{marginTop: 2}} >About Us</Typography>
        <Typography variant='p' component='p' sx={{marginTop: 2}} >Blog</Typography>
        <Typography variant='p' component='p' sx={{marginTop: 2}} >Career</Typography>
        </div>
</Grid>
  <Grid item xs={12} md={3}>
  <div>
        <Typography variant='h6' component='p' sx={{marginTop: 2}} >Customer Help</Typography>
        <Typography variant='p' component='p' sx={{marginTop: 2}} >Privacy Policy</Typography>
        <Typography variant='p' component='p' sx={{marginTop: 2}} >Do Not Sell My Information</Typography>
        </div>
  </Grid>
  <Grid item xs={6} sx={{my: 4}}>
       <img src={logo} width={100} alt='logo'></img>
  </Grid>
  <Grid item xs={6} sx={{display:'flex', justifyContent:'end', alignItems:'center', gap: 3}}>
        <FacebookOutlinedIcon/>
        <InstagramIcon/>
  </Grid>
</Grid>

    </Container>
    <Container maxWidth='xl' sx={{py: 2}}>
    <Grid container spacing={2}>
  <Grid item xs={12} sx={{textAlign:'center'}}>
  <Divider sx={{borderColor:'#484848', marginBottom: 2}}/>
  Dealer Pro. © 2024. All rights reserved.
    </Grid>
</Grid>
    </Container>
    </Box>
    </>
  )
}

export default LandFooter;