import React, {useState, useEffect} from 'react';
import {
  Box, Button, Card, Container, Grid, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/gaaura.png';
import temp from '../../assets/images/cl.png';
import studio from '../../assets/images/enviro.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Vertabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';
import dashboardImg from '../../assets/images/dashboard.png';
import MediaCard from '../../components/card/Card';
import figma from '../../assets/images/dashboard.png';
import infindable from '../../assets/images/Automotive-Retail-Solutions-Digital-Dealer-Tools-India.webp'
import CustSlider from '../../components/slider/Slider';
import human from '../../assets/images/human.png';
import p1 from '../../assets/images/pioneer1.png';
import p2 from '../../assets/images/pioneer2.png';
import p3 from '../../assets/images/pioneer3.png';
import p4 from '../../assets/images/pioneer4.png';
import p5 from '../../assets/images/pioneer5.png';
import p6 from '../../assets/images/pioneer6.png';


const Home = ({setLoader}) => {

  return (
    <div>
    <div className='firstSection'>
    <Container maxWidth="xl">
        <Box sx={{textAlign:'center', marginTop:{ xs:'130px', md:'200px'}}}>
          <Typography variant='h2' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', marginTop: 2, lineHeight: 1.5}}>Welcome to Pinnacle Dealer Management Software</Typography>
          <Typography variant='h6' sx={{fontSize:'20px', fontWeight:'normal', marginTop: 4}}>At Pinnacle SaaS tech, we specialize in providing cutting-edge dealer management software solutions tailored to meet the unique needs of automotive, equipment, and other dealerships. Our cloud-based platform  offers comprehensive features designed to streamline operations, enhance productivity, and maximize profitability.</Typography>
          <button className='customizeButton'>Request Demo</button>
        </Box>
        <Box className="fourthSec" sx={{position:'relative', paddingBottom: 10}}>
        <Container maxWidth='xl' sx={{textAlign:'center'}}>
        <img src={dashboardImg} alt='dashimg' width='100%'></img>
        </Container>
</Box>
    </Container>
    </div>
    <Box className="secondSec" sx={{position:'relative'}}>
    <Container maxWidth="xl">
          <Typography variant='h5' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'normal', textAlign:'center'}}>
          Preferred by our esteemed clientele
          </Typography>
          <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', p: 5, gap: 5, flexWrap:'wrap'}}>
            <div style={{textAlign:'center', padding: '20px'}}>
                <img src={takepic} alt='takepic'/>
            </div>
            <div style={{textAlign:'center',  padding: '20px'}}>
                <img src={temp} alt='choosetemp'/>
            </div>
            <div style={{textAlign:'center',  padding: '20px'}}>
                <img src={studio} alt='studio'/>
            </div>
        </Box>
    </Container>
       </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
        <Box className="whatoffer" >
           <Grid container spacing={4}>
           <Grid item xs={12} md={6}>
            <Card sx={{background:'none', boxShadow:'none'}}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              <img src={human} alt="mail" width='100%'></img>
              </Grid>
              <Grid item xs={9}>
              <Box>
                    <Typography sx={{mb: 1, fontWeight:'bold'}} variant="h4">Seamless Integration</Typography>
                    <Typography variant="body1" sx={{mt: 2, lineHeight: '28px', fontSize: '17px'}}>Our software integrates with top third-party solutions (accounting, CRM, marketing) for seamless workflows, eradicating data silos in your organization.</Typography>
                </Box>
              </Grid>
            </Grid>
            </Card>
 </Grid>
 <Grid item xs={12} md={6}>
            <Card sx={{background:'none', boxShadow:'none'}}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              <img src={human} alt="mail" width='100%'></img>
              </Grid>
              <Grid item xs={9}>
              <Box>
                    <Typography sx={{mb: 1, fontWeight:'bold'}} variant="h4">Real-Time Insights</Typography>
                    <Typography variant="body1" sx={{mt: 2, lineHeight: '28px', fontSize: '17px'}}>Gain valuable insights into your dealerships.</Typography>
                </Box>
              </Grid>
            </Grid>
            </Card>
 </Grid>
 <Grid item xs={12} md={6}>
            <Card sx={{background:'none', boxShadow:'none'}}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              <img src={human} alt="mail" width='100%'></img>
              </Grid>
              <Grid item xs={9}>
              <Box>
                    <Typography sx={{mb: 1, fontWeight:'bold'}} variant="h4">User-Friendly Interface</Typography>
                    <Typography variant="body1" sx={{mt: 2, lineHeight: '28px', fontSize: '17px'}}>User-friendly software for diverse dealership needs, customizable to fit unique requirements.</Typography>
                </Box>
              </Grid>
            </Grid>
            </Card>
 </Grid>
 <Grid item xs={12} md={6}>
            <Card sx={{background:'none', boxShadow:'none'}}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              <img src={human} alt="mail" width='100%'></img>
              </Grid>
              <Grid item xs={9}>
              <Box>
                    <Typography sx={{mb: 1, fontWeight:'bold'}} variant="h4">Scalability</Typography>
                    <Typography variant="body1" sx={{mt: 2, lineHeight: '28px', fontSize: '17px'}}>From single-location to nationwide, our software grows with you. Add users, locations, and features as your dealership expands.</Typography>
                </Box>
              </Grid>
            </Grid>
            </Card>
 </Grid>
 <Grid item xs={12} md={6}>
            <Card sx={{background:'none', boxShadow:'none'}}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              <img src={human} alt="mail" width='100%'></img>
              </Grid>
              <Grid item xs={9}>
              <Box>
                    <Typography sx={{mb: 1, fontWeight:'bold'}} variant="h4">Efficiency & Automation</Typography>
                    <Typography variant="body1" sx={{mt: 2, lineHeight: '28px', fontSize: '17px'}}>Automate inventory, sales, CRM, and finance with our dealer management software, freeing your team for customer service and sales.</Typography>
                </Box>
              </Grid>
            </Grid>
            </Card>
 </Grid>
 <Grid item xs={12} md={6}>
            <Card sx={{background:'none', boxShadow:'none'}}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              <img src={human} alt="mail" width='100%'></img>
              </Grid>
              <Grid item xs={9}>
              <Box>
                    <Typography sx={{mb: 1, fontWeight:'bold'}} variant="h4">Cloud-based infrastructure</Typography>
                    <Typography variant="body1" sx={{mt: 2, lineHeight: '28px', fontSize: '17px'}}>Cloud-based infrastructure offers flexibility, scalability, and accessibility for seamless operations and enhanced productivity..</Typography>
                </Box>
              </Grid>
            </Grid>
            </Card>
 </Grid>
</Grid>
          </Box>
        </Box>
        <Box className="fourthSec" sx={{position:'relative'}}>
        <Container maxWidth='xl' sx={{textAlign:'center'}}>
        <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'normal', textAlign:'center'}}>
        Pioneering Dealer Connections with <br></br> ohoh DMS
        </Typography>
        <Grid container spacing={3} sx={{marginTop: 3, height: '100%'}}>
  <Grid item xs={12} md={4} sx={{height:'100%'}}>
    <MediaCard img={p1} text={''} head={'Facilitation of service contracts management, ensuring clarity and compliance in service agreements.'}/>
     </Grid>
  <Grid item xs={12} md={4}>
  <MediaCard img={p2} text={''} head={'Automation of service processes from gate pass management to billing and invoicing, enhancing efficiency.'}/></Grid>
  <Grid item xs={12} md={4}>
  <MediaCard img={p3} text={''} head={'Effective monitoring of adherence to service standards and Key Performance Indicators (KPIs) to ensure service excellence.'}/></Grid>
  <Grid item xs={12} md={4}>
  <MediaCard img={p4} text={''} head={'Advanced service planning tools maintain a comprehensive service history for each product, ensuring timely customer engagement.'}/></Grid>
<Grid item xs={12} md={4}>
<MediaCard img={p5} text={''} head={'Tracking technician job efficiency allows for performance assessment and appropriate incentivization, ensuring optimal service delivery.'}/></Grid>
<Grid item xs={12} md={4}>
<MediaCard img={p6} text={''} head={'Digitizing customer service offers and schemes through coupon management enhances accessibility and convenience for customers.'}/></Grid>
</Grid>
        </Container>
</Box>
    <Box className="thirdSec" sx={{position:'relative'}}>
    <Container className='whatoffer' maxWidth="xl">
    <Typography className='heading1' variant='h5' color='primary.main' sx={{fontFamily:"'Sora', sans-serif",  textAlign:'center'}}>
    Seek Beyond
          </Typography>
          <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', marginTop: 4}}>
          DelrPro pioneers a paradigm shift in collaborative standards within the industry. </Typography>
          <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'normal', textAlign:'center', my: 5}}>
          DelrPro reimagines collaboration, fusing AI advancements to unite customers, distributors, dealers, and OEMs, reshaping industry benchmarks.</Typography>
          <img src={infindable} alt='infindable' style={{marginTop: '50px'}} width='100%'></img>
    </Container>
        </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
    <Container maxWidth="xl">
    <Typography variant='h5' color='primary.main' sx={{fontFamily:"'Sora', sans-serif",  textAlign:'center', fontWeight:'bold'}}>
    User friendly
          </Typography>
          <Typography variant='h4' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', my: 3}}>
          Diverse Device Solutions to Meet Your Every Need        </Typography>
          <img src='https://www.ohoh.in/wp-content/uploads/2023/12/Ohoh-DMS-3-2048x1385.webp
' alt='infindable' width={'100%'}></img>
    </Container>
        </Box>
        <Box className="thirdSec bg" sx={{position:'relative'}}>
        <Typography variant='h4' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', marginBottom: 5}}>
        What Our Clients Say About Us
        </Typography>
      <CustSlider/>
    </Box>
    <Box className="thirdSec" sx={{position:'relative'}}>
    <Container className='whatoffer' maxWidth="xl">
    <Typography className='heading1' variant='h5' color='primary.main' sx={{fontFamily:"'Sora', sans-serif",  textAlign:'center'}}>
    Explore our company
          </Typography>
          <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', marginTop: 4}}>
          Start your trail today      </Typography>
          <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'normal', textAlign:'center', my: 5}}>
          Supercharge your Dealership with the best DMS acceleration</Typography>
          {/* <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 5}}>
            <div style={{textAlign:'center', padding: '20px', display:'flex', alignItems:'center', gap: 5}}>
                <img src={takepic} alt='takepic'/>
                <Typography variant='h6'>Free trail for 7 Days</Typography>
            </div>
            <div style={{textAlign:'center',  padding: '20px', display:'flex', alignItems:'center', gap: 5}}>
                <img src={temp} alt='choosetemp'/>
                <Typography variant='h6'>Free Integration</Typography>
            </div>
            <div style={{textAlign:'center',  padding: '20px', display:'flex', alignItems:'center', gap: 5}}>
                <img src={studio} alt='studio'/>
                <Typography variant='h6'>24/7 Support</Typography>
            </div>
        </Box> */}
          <button className='customizeButton'>Request Demo</button>
    </Container>
        </Box>
    </div>
  )
}

export default Home