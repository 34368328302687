import { Box, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import takepic from "../../assets/images/takeapick.png";
import temp from "../../assets/images/choosetemp.png";
import studio from "../../assets/images/studio.png";
import MediaCard from "../../components/card/Card";

const Aboutus = () => {
  return (
    <div>
      <div className="firstSection">
        <Container maxWidth="xl">
          <Box
            sx={{
              textAlign: "center",
              marginTop:{ xs:'130px', md:'200px'}
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "'Sora', sans-serif",
                fontWeight: "bold",
                marginTop: 2,
              }}
            >
              Building the world’s best cloud based dealer management system
              (DMS)
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                marginTop: 4,
                lineHeight: "38px",
              }}
            >
              Introducing the Apex Cloud DMS: Redefining dealership management
              for the future. Unleash unparalleled efficiency, connectivity, and
              user experience. Elevate your dealership to new heights with
              precision-driven performance. Welcome to excellence in automotive
              retail.
            </Typography>
            <button className="customizeButton">Request Demo</button>
          </Box>
        </Container>
      </div>
      <Box className="secondSec" sx={{ position: "relative" }}>
        <Container maxWidth="xl">
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    fontFamily: "'Sora', sans-serif",
                    fontWeight: "normal",
                    textAlign: "start",
                  }}
                >
                  About Us
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "'Sora', sans-serif",
                    fontWeight: "bold",
                    textAlign: "start",
                    mt: 1,
                  }}
                >
                  OhOh unleashes passion, creativity, and redefined excellence
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                  }}
                >
                  Introducing the Apex Cloud DMS: Redefining dealership
                  management for the future. Unleash unparalleled efficiency,
                  connectivity, and user experience. Elevate your dealership to
                  new heights with precision-driven performance. Welcome to
                  excellence in automotive retail.
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    mt: 1,
                  }}
                >
                  We believe in pushing boundaries, embracing challenges, and
                  shaping a future filled with resilience and creativity. Join
                  us on this exciting journey as we redefine possibilities and
                  pursue excellence.
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ textAlign: "end" }}>
                <img
                  src="https://www.ohoh.in/wp-content/uploads/2023/12/Dealer-Management-System-India-Best-Dealer-maintainence-software.webp"
                  alt="ig"
                  width='100%'
                ></img>
              </Grid>
              <Grid item md={6} xs={12} sx={{ display: "flex", alignItems: "end" }}>
                <img
                  src="https://www.ohoh.in/wp-content/uploads/2023/12/Dealer-Management-System-India-Efficient-Dealer-Workflow-Solutions.webp"
                  alt="img"
                  width='100%'
                ></img>
              </Grid>
              <Grid item md={6} xs={12} sx={{ textAlign: "end" }}>
                <img
                  src="https://www.ohoh.in/wp-content/uploads/2023/12/Dealer-Management-System-India-Customer-Relationship-Management-for-Dealers.webp"
                  alt="ig"
                  width='100%'
                ></img>
              </Grid>
              <Grid item md={6} xs={12} sx={{ display: "flex", alignItems: "end" }}>
                <img
                  src="https://www.ohoh.in/wp-content/uploads/2023/12/Dealer-Management-System-India-Affordable-DMS-Solutions.webp"
                  alt="img"
                  width='100%'
                ></img>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box className="fourthSec" sx={{ position: "relative" }}>
        <Container
          className="whatoffer1"
          maxWidth="xl"
          sx={{ textAlign: "center" }}
        >
          <Typography
            variant="h6"
            color="primary"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            Products
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "normal",
              textAlign: "center",
              mt: 2,
            }}
          >
            Regulation and Excellence in Product Quality
          </Typography>
          <Grid container spacing={3} sx={{ marginTop: 3, height: "100%" }}>
            <Grid item md={6} xs={12} sx={{ height: "100%" }}>
              <MediaCard
                img={
                  "https://www.ohoh.in/wp-content/uploads/2023/12/group_1261157053.webp"
                }
                text={
                  "Tailor the hierarchy and responsibilities of both employees and workers to align with your organization’s needs"
                }
                head={"You have control over Dealer and Customers"}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MediaCard
                img={
                  "https://www.ohoh.in/wp-content/uploads/2024/01/Dealer-managment-system-Affordableble-dealer-managment-system-2048x841.webp"
                }
                text={
                  "Extract who wants what, when, and how important it really is for them. Discover blockers, low-hanging fruits, last requests, or common needs."
                }
                head={"Map their feedback to actual features and requests"}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="thirdSec" sx={{ position: "relative" }}>
        <Container className="whatoffer" maxWidth="xl">
          <Typography
            variant="h6"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 4,
            }}
          >
            Sync customer’s data from your CRM and CS tools{" "}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "normal",
              textAlign: "center",
              my: 5,
            }}
          >
            Know your customer’s worth , deal stage etc...{" "}
          </Typography>
          <img
            src="https://www.ohoh.in/wp-content/uploads/2024/01/Best-dealer-management-in-india-best-customer-data-management-in-india-top-dms-providers_4x-2048x573.webp"
            alt="infindable"
            width="100%"
            style={{ marginTop: "50px" }}
          ></img>
        </Container>
      </Box>
      <Box className="secondSec" sx={{ position: "relative" }}>
        <Container maxWidth="xl">
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={5}>
              <Grid item md={6} xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <img
                    src="https://www.ohoh.in/wp-content/uploads/2023/12/Dealer-Management-System-India-Leading-DMS-Technology-in-India-No.1-dms-software-in-india.webp"
                    alt="ig"
                    width={300}
                  ></img>
                  <img
                    src="https://www.ohoh.in/wp-content/uploads/2023/12/Dealer-Management-System-India-Leading-DMS-Technology-in-India-No.1-dms-software-in-india.webp"
                    alt="img"
                    width={300}
                  ></img>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <img
                    src="https://www.ohoh.in/wp-content/uploads/2023/12/Dealer-Management-System-India-Leading-DMS-Technology-in-India-No.1-dms-software-in-india.webp"
                    alt="ig"
                    width={300}
                  ></img>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <img
                    src="https://www.ohoh.in/wp-content/uploads/2023/12/Dealer-Management-System-India-Leading-DMS-Technology-in-India-No.1-dms-software-in-india.webp"
                    alt="ig"
                    width={300}
                  ></img>
                  <img
                    src="https://www.ohoh.in/wp-content/uploads/2023/12/Dealer-Management-System-India-Leading-DMS-Technology-in-India-No.1-dms-software-in-india.webp"
                    alt="img"
                    width={300}
                  ></img>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    fontFamily: "'Sora', sans-serif",
                    fontWeight: "normal",
                    textAlign: "start",
                  }}
                >
                  Team
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "'Sora', sans-serif",
                    fontWeight: "bold",
                    textAlign: "start",
                    my: 2,
                  }}
                >
                  Empowering Change, One Entrepreneurial Journey at a Time.
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    my: 2,
                  }}
                >
                  Our business is proudly built by a group of dynamic women
                  entrepreneurs hailing from the rural heart of Tiruvannamalai,
                  Tamil Nadu. Breaking barriers and shattering stereotypes, our
                  team embodies the spirit of resilience, innovation, and
                  determination.
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    my: 2,
                  }}
                >
                  In the scenic landscapes of rural Tiruvannamalai, our journey
                  unfolds as a testament to the strength and vision of women in
                  business. Rooted in our community, we are passionate about
                  creating opportunities, fostering growth, and inspiring others
                  to dream big.
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    my: 2,
                  }}
                >
                  With a commitment to excellence and a collaborative spirit,
                  our diverse team is redefining entrepreneurship in our region.
                  Join us on this empowering journey, where every success story
                  is a celebration of women's strength, entrepreneurship, and
                  the transformative power of collective ambition.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box className="thirdSec" sx={{ position: "relative" }}>
        <Container className="whatoffer" maxWidth="xl">
          <Typography
            className="heading1"
            variant="h5"
            color="primary.main"
            sx={{ fontFamily: "'Sora', sans-serif", textAlign: "center" }}
          >
            Explore our company
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 4,
            }}
          >
            Start your trail today{" "}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "normal",
              textAlign: "center",
              my: 5,
            }}
          >
            Supercharge your Dealership with the best DMS acceleration
          </Typography>
          <button className="customizeButton">Request Demo</button>
        </Container>
      </Box>
    </div>
  );
};

export default Aboutus;
