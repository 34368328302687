import { Box, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import takepic from "../../assets/images/takeapick.png";
import temp from "../../assets/images/choosetemp.png";
import studio from "../../assets/images/studio.png";

const Integration = () => {
  return (
    <div>
      <div className="firstSection">
        <Container maxWidth="xl">
          <Box
            sx={{
              textAlign: "center",
              marginTop:{ xs:'130px', md:'200px'}
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "'Sora', sans-serif",
                fontWeight: "bold",
                marginTop: 2,
              }}
            >
             "We seamlessly integrate across numerous platforms."
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                marginTop: 4,
                lineHeight: "38px",
              }}
            >
              Seamlessly integrated into multiple platforms, our DMS offers a unified ecosystem for comprehensive business management. It connects sales, inventory, finance, and customer relations effortlessly, ensuring real-time data synchronization and enhanced operational efficiency. Bridging these platforms ensures a seamless user experience, optimizing business processes.
            </Typography>
          </Box>
          <Box
            className="fourthSec"
            sx={{ position: "relative", paddingBottom: 10 }}
          >
            <Container maxWidth="xl" sx={{ textAlign: "center" }}></Container>
          </Box>
        </Container>
      </div>
      <Box className="secondSec" sx={{ position: "relative" }}>
        <Container maxWidth="xl">
          <Typography
            variant="h4"
            color='primary'
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "normal",
              textAlign: "start",
            }}
          >
            Most popular DMS integrations
          </Typography>
          <Box sx={{pt: 3}}>
           <Grid container spacing={3}>
  <Grid item md={6} xs={12}>
            <Card sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 3, p: 2, bgcolor:'light.light', boxShadow:'none', borderRadius: 2, border:'1px solid #b0b0b0'}}>
                <img src="https://www.ohoh.in/wp-content/uploads/2023/12/image_60.webp" alt="mail" width={100}></img>
                <Box>
                    <Typography sx={{mb: 1}} variant="h5">Email</Typography>
                    <Typography variant="p" sx={{mt: 2, lineHeight: '28px', fontSize: '18px'}}>Elevate your dealership's communication and document management with our Email Integration for Dealer Management System, streamlining efficiency.</Typography>
                </Box>
            </Card>
 </Grid>
  <Grid item md={6} xs={12}>
  <Card sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 3, p: 2, bgcolor:'light.light', boxShadow:'none', borderRadius: 2, border:'1px solid #b0b0b0'}}>
                <img src="https://www.ohoh.in/wp-content/uploads/2023/12/image_61.webp" alt="mail" width={100}></img>
                <Box>
                    <Typography sx={{mb: 1}} variant="h5">WhatsApp</Typography>
                    <Typography variant="p" sx={{mt: 2, lineHeight: '28px', fontSize: '18px'}}>Boost dealership efficiency with WhatsApp, facilitating seamless communication and connectivity. Stay effortlessly connected with your network.</Typography>
                </Box>
            </Card>
  </Grid>
  <Grid item md={6} xs={12}>
  <Card sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 3, p: 2, bgcolor:'light.light', boxShadow:'none', borderRadius: 2, border:'1px solid #b0b0b0'}}>
                <img src="https://www.ohoh.in/wp-content/uploads/2023/12/image_62.webp" alt="mail" width={100}></img>
                <Box>
                    <Typography sx={{mb: 1}} variant="h5">SMS</Typography>
                    <Typography variant="p" sx={{mt: 2, lineHeight: '28px', fontSize: '18px'}}>Maximize connectivity and streamline communication with our SMS integration solution, ensuring swift updates and notifications for enhanced efficiency.</Typography>
                </Box>
            </Card>
  </Grid>
  <Grid item md={6} xs={12}>
  <Card sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 3, p: 2, bgcolor:'light.light', boxShadow:'none', borderRadius: 2, border:'1px solid #b0b0b0'}}>
                <img src="https://www.ohoh.in/wp-content/uploads/2023/12/image_63.webp" alt="mail" width={100}></img>
                <Box>
                    <Typography sx={{mb: 1}} variant="h5">Road Side Assistance</Typography>
                    <Typography variant="p" sx={{mt: 2, lineHeight: '28px', fontSize: '18px'}}>Integrate Roadside Assistance into our DMS for swift support, enhancing customer satisfaction and service efficiency.</Typography>
                </Box>
            </Card>
  </Grid>
<Grid item md={6} xs={12}>
<Card sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 3, p: 2, bgcolor:'light.light', boxShadow:'none', borderRadius: 2, border:'1px solid #b0b0b0'}}>
                <img src="https://www.ohoh.in/wp-content/uploads/2023/12/image_64.webp" alt="mail" width={100}></img>
                <Box>
                    <Typography sx={{mb: 1}} variant="h5">E Way Bill</Typography>
                    <Typography variant="p" sx={{mt: 2, lineHeight: '28px', fontSize: '18px'}}>Forge streamlined logistics and compliance through our DMS's seamless integration with E-Way Bill, fostering an efficient and automated operational landscape.</Typography>
                </Box>
            </Card>
             </Grid>
  <Grid item md={6} xs={12}>
  <Card sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 3, p: 2, bgcolor:'light.light', boxShadow:'none', borderRadius: 2, border:'1px solid #b0b0b0'}}>
                <img src="https://www.ohoh.in/wp-content/uploads/2023/12/frame_1261157038.webp" alt="mail" width={100}></img>
                <Box>
                    <Typography sx={{mb: 1}} variant="h5">Billing Software</Typography>
                    <Typography variant="p" sx={{mt: 2, lineHeight: '28px', fontSize: '18px'}}>Introducing seamless integration of E-Way Bill into our DMS, revolutionizing logistics and compliance for unparalleled efficiency and automation.</Typography>
                </Box>
            </Card>
             </Grid>
</Grid>
          </Box>
        </Container>
      </Box>
      <Box className="thirdSec" sx={{ position: "relative" }}>
        <Container className="whatoffer" maxWidth="xl">
          <Typography
            className="heading1"
            variant="h5"
            color="primary.main"
            sx={{ fontFamily: "'Sora', sans-serif", textAlign: "center" }}
          >
            Explore our company
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 4,
            }}
          >
            Start your trail today{" "}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "normal",
              textAlign: "center",
              my: 5,
            }}
          >
            Supercharge your Dealership with the best DMS acceleration
          </Typography>
          <button className="customizeButton">Request Demo</button>
        </Container>
      </Box>
    </div>
  );
};

export default Integration;
